import Actions from "../constants";
import DialogueConstants from "../../components/constants";
import undoable, { includeAction } from "redux-undo";

export const setDialogueGraphData = (currentState, action) => {
  if (action.type === Actions.REVERT_DIALOGUE_GRAPH_FULFILLED) {
    return [];
  } else if (action.type !== Actions.SET_DIALOGUE_GRAPH) {
    if (currentState?.present && !Array.isArray(currentState?.present))
      return [];
    return currentState || [];
  }
  return action.payload;
};

export const setDialogueGraphInfo = (currentState, action) => {
  if (action.type !== Actions.SET_DIALOGUE_GRAPH_INFO) {
    return currentState;
  }

  return action.payload;
};

export const undoableDialogueGraphData = undoable(setDialogueGraphData, {
  ignoreInitialState: true,
  debug: false,
  syncFilter: true,
  undoType: Actions.UNDO_GRAPH,
  redoType: Actions.REDO_GRAPH,
  clearHistoryType: Actions.CLEAR_GRAPH_HISTORY,
  filter: function filterState(action, currentState, previousHistory) {
    let { past, present, future } = previousHistory;
    return (
      includeAction(Actions.SET_DIALOGUE_GRAPH)(
        action,
        currentState,
        previousHistory
      ) && present.length !== 0
    ); // only add to history if not empty
  },
});

export const setGraphUndoRedo = (currentState, action) => {
  if (action.type === Actions.UNDO_GRAPH || action.type === Actions.REDO_GRAPH)
    return true;

  return false;
};

export const setDialogueGraphs = (currentState, action) => {
  if (action.type !== Actions.SET_DIALOGUE_GRAPHS) {
    return currentState;
  }

  return action.payload;
};

export const setDialogueUrls = (currentState, action) => {
  if (action.type !== Actions.SET_DIALOGUE_URLS) {
    return currentState;
  }

  return action.payload;
};

export const setDialogueGraphBlueprints = (currentState, action) => {
  if (action.type !== Actions.SET_DIALOGUE_GRAPH_BLUEPRINTS) {
    return currentState;
  }

  return action.payload;
};

export const setGraphId = (currentState, action) => {
  if (action.type !== Actions.SET_GRAPH_ID) {
    return currentState;
  }

  return action.graphId;
};

export const setDialogueSelectedGraphNode = (currentState, action) => {
  if (action.type !== Actions.FETCH_DIALOGUE_GRAPH) {
    return currentState;
  }

  return action.selectedGraphNode;
};

export const setDialogueGraphDataSavedAt = (currentState, action) => {
  if (action.type !== Actions.SAVE_DIALOGUE_GRAPH_FULFILLED) {
    return currentState;
  }

  return action.timestamp;
};

export const setDialogueGraphDataSavedStatus = (currentState, action) => {
  if (action.type === Actions.SET_DIALOGUE_GRAPH_SAVED_STATUS) {
    return action.payload;
  } else if (action.type === Actions.SAVE_DIALOGUE_GRAPH_FULFILLED) {
    return action.status;
    // } else if (action.type === Actions.SET_DIALOGUE_GRAPH) {
    //   return DialogueConstants.SAVED_STATUS.SUCCESS;
  } else if (action.type === Actions.SAVE_DIALOGUE_GRAPH) {
    return DialogueConstants.SAVED_STATUS.IN_PROGRESS;
  }

  return currentState;
};

export const setDialogueGraphDataPublishedAt = (currentState, action) => {
  if (action.type !== Actions.PUBLISH_DIALOGUE_GRAPH_FULFILLED) {
    return currentState;
  }

  return action.timestamp;
};

export const setDialogueGraphDataTrainedAt = (currentState, action) => {
  if (action.type !== Actions.TRAIN_DIALOGUE_GRAPH_FULFILLED) {
    return currentState;
  }

  return action.timestamp;
};

export const setDialogueGraphDataPublishedStatus = (currentState, action) => {
  if (action.type === Actions.SET_DIALOGUE_GRAPH_DATA_PUBLISHED_STATUS) {
    return action.payload;
  } else if (action.type === Actions.PUBLISH_DIALOGUE_GRAPH_FULFILLED) {
    return action.status;
  } else if (action.type === Actions.SAVE_DIALOGUE_GRAPH_FULFILLED) {
    return "unpublished";
  } else if (action.type === Actions.PUBLISH_DIALOGUE_GRAPH) {
    return "inProgress";
  } else if (action.type === Actions.PUBLISH_DIALOGUE_GRAPH_ERROR) {
    return "error";
  }

  return currentState;
};

export const setActionNodeTemplate = (currentState, action) => {
  if (action.type !== Actions.SET_ACTION_NODE_TEMPLATE) {
    return currentState;
  }

  return action.payload;
};

export const setDialogueGraphVariables = (currentState, action) => {
  if (action.type !== Actions.SET_DIALOGUE_GRAPH_VARIABLES) {
    return currentState;
  }

  return action.payload;
};

export const setProactiveMessage = (currentState, action) => {
  if (action.type !== Actions.SET_PROACTIVE_MESSAGE) {
    return currentState;
  }

  return action.payload;
};

export const setEedNotificationData = (currentState, action) => {
  if (action.type !== Actions.SET_EED_NOTIFICATION_DATA) {
    return currentState;
  }

  return action.payload;
};

export const setDialogueNodeSearchQuery = (currentState, action) => {
  if (action.type !== Actions.SET_DIALOGUE_NODE_SEARCH_QUERY) {
    return currentState;
  }

  return action.payload;
};

export const setDemoDrawerState = (currentState = false, action) => {
  if (action.type === Actions.SET_DEMO_DRAWER_STATE) {
    return action.payload;
  }
  return currentState;
};

export const setAiBuildDrawerState = (currentState = false, action) => {
  if (action.type === Actions.SET_AI_BUILD_DRAWER_STATE) {
    return action.payload;
  }
  return currentState;
};

export const setDialogueGraphPublishedAt = (currentState = null, action) => {
  if (action.type === Actions.SET_DIALOGUE_GRAPH_PUBLISHED_AT) {
    return action.payload;
  }
  return currentState;
};

export const setGraphLockStatus = (currentState, action) => {
  if (action.type !== Actions.SET_GRAPH_LOCK_STATUS) {
    return currentState;
  }
  return action.payload;
};

// Handle GRAPH_LOCK_ERROR action to properly set the graphLockStatus
export const graphLockErrorReducer = (currentState, action) => {
  if (action.type !== Actions.GRAPH_LOCK_ERROR) {
    return currentState;
  }

  // If the error has a status of GRAPH_BUSY_SAME_OWNER, we want to preserve that
  if (action.payload?.status === "GRAPH_BUSY_SAME_OWNER") {
    console.log("Setting lock status to GRAPH_BUSY_SAME_OWNER in reducer");
    return action.payload;
  }

  // Otherwise, we set it to GRAPH_BUSY (default error state)
  return { ...action.payload, status: "GRAPH_BUSY" };
};

export const setGraphLockUuid = (currentState, action) => {
  // Initialize from sessionStorage on first load
  if (currentState === undefined) {
    const storedLockUuid =
      typeof window !== "undefined" ? sessionStorage.getItem("lockUuid") : null;
    return storedLockUuid;
  }

  if (action.type !== Actions.SET_GRAPH_LOCK_UUID) {
    return currentState;
  }

  // Store new value in sessionStorage
  if (typeof window !== "undefined") {
    if (action.payload) {
      sessionStorage.setItem("lockUuid", action.payload);
    } else {
      sessionStorage.removeItem("lockUuid");
    }
  }

  return action.payload;
};

export const setDialogueGraphVersions = (currentState = [], action) => {
  if (action.type === Actions.SET_DIALOGUE_GRAPH_VERSIONS) {
    return action.payload;
  }
  return currentState;
};

export const setDialogueGraphVersionRestoreStatus = (
  currentState = null,
  action
) => {
  if (action.type === Actions.RESTORE_DIALOGUE_GRAPH_VERSION) {
    return "inProgress";
  } else if (action.type === Actions.RESTORE_DIALOGUE_GRAPH_VERSION_FULFILLED) {
    return "success";
  } else if (action.type === Actions.RESTORE_DIALOGUE_GRAPH_VERSION_ERROR) {
    return "error";
  }
  return currentState;
};

export const setGraphErrors = (currentState, action) => {
  if (action.type !== Actions.SET_GRAPH_ERRORS) {
    return currentState;
  }

  return action.payload;
};

export const setAiGenerationResult = (currentState = null, action) => {
  if (action.type === Actions.GENERATE_AI_GRAPH_PATH_FULFILLED) {
    return action.payload;
  } else if (action.type === Actions.GENERATE_AI_GRAPH_PATH) {
    return null;
  }
  return currentState;
};

export const setAiGenerationError = (currentState = null, action) => {
  if (action.type === Actions.GENERATE_AI_GRAPH_PATH_REJECTED) {
    return action.payload;
  } else if (action.type === Actions.GENERATE_AI_GRAPH_PATH) {
    return null;
  }
  return currentState;
};

export const setIsGeneratingAiPath = (currentState = false, action) => {
  if (action.type === Actions.GENERATE_AI_GRAPH_PATH) {
    return true;
  } else if (
    action.type === Actions.GENERATE_AI_GRAPH_PATH_FULFILLED ||
    action.type === Actions.GENERATE_AI_GRAPH_PATH_REJECTED
  ) {
    return false;
  }
  return currentState;
};
